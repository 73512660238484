import React, { useState, useRef } from "react";
import Slider from "react-slick";
import { Container, Row, Col } from "react-bootstrap";
import { HomeImages } from "./components/HomeImages";
import style from "./style.module.scss";
import InstagramLogo from "../../assets/ig.png";

export default function Home() {
  const refSlider = useRef();

  const [state, setState] = useState({ current: 0 });
  const [arrows, setArrows] = useState(false);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1199.98,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
    beforeChange: (current, next) =>
      setState({ oldSlide: current, activeSlide: next }),
    afterChange: (current) => setState({ current: current }),
  };

  return (
    <div>
      <div className={style.content}>
        <Container>
          <Row
            onMouseEnter={() => setArrows(true)}
            onMouseLeave={() => setArrows(false)}
          >
            <Col xs={12} xl={8}>
              <div className={style.numbers}>
                <div
                  className={style.arrLeft}
                  onClick={() => refSlider.current.slickPrev()}
                >
                  ←
                </div>
                {state.current + 1 || state.oldSlide + 1 || 1} /
                {HomeImages.length}
                <div
                  className={style.arrRight}
                  onClick={() => refSlider.current.slickNext()}
                >
                  →
                </div>
              </div>
              <Slider
                ref={(slider) => (refSlider.current = slider)}
                arrows={arrows}
                {...settings}
              >
                {HomeImages.map((val, index) => {
                  return (
                    <div key={index} className={style.itemContainer}>
                      <img
                        className={style.itemImg}
                        src={val}
                        width="auto"
                        height="600"
                        alt=""
                      />
                    </div>
                  );
                })}
              </Slider>
            </Col>
            <Col
              xs={12}
              xl={{ span: 3, offset: 1 }}
              className="pointer-events-none"
            >
              <div className={style.textContainer}>
                <div className={style.text}>Interior.</div>
                <div className={style.text}>Exhibition.</div>
                <div className={style.text}>Architecture.</div>
                <div className={style.text}>Promotion Product.</div>
              </div>
            </Col>
            <Col xs={12} className="d-lg-none">
              <div className={style.footer}>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.instagram.com/karyadelano/?hl=en"
                >
                  <img
                    width="36px"
                    className={style.IGLogo}
                    src={InstagramLogo}
                    alt="Instagram"
                  />
                </a>
                <p className={style.delano}>Delano</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
