import Interior1 from "../../../assets/Interior/3. Interior 1.jpg";
import Interior2 from "../../../assets/Interior/4. Interior 2.jpg";
import Interior3 from "../../../assets/Interior/5. Interior 3.jpg";
import Interior4 from "../../../assets/Interior/6. Interior 4.jpg";
import Interior5 from "../../../assets/Interior/7. Interior 5.jpg";
import Interior6 from "../../../assets/Interior/8. Interior 6.jpg";
import Interior7 from "../../../assets/Interior/9. Interior 7.jpg";
import Interior8 from "../../../assets/Interior/10. Interior 8.jpg";
import Interior9 from "../../../assets/Interior/INTERIOR DELANO-1.jpg";
import Interior10 from "../../../assets/Interior/INTERIOR DELANO-2.jpg";
import Interior11 from "../../../assets/Interior/INTERIOR DELANO-3.jpg";
import Interior12 from "../../../assets/Interior/INTERIOR DELANO-5.jpg";
import Interior13 from "../../../assets/Interior/INTERIOR DELANO-6.jpg";
import Interior14 from "../../../assets/Interior/INTERIOR DELANO-7.jpg";
import Interior15 from "../../../assets/Interior/INTERIOR DELANO-8.jpg";

export const InteriorImages = [
  Interior1,
  Interior2,
  Interior3,
  Interior4,
  Interior5,
  Interior6,
  Interior7,
  Interior8,
  Interior9,
  Interior10,
  Interior11,
  Interior12,
  Interior13,
  Interior14,
  Interior15,
];
