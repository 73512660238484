import Home1 from "../../../assets/Home/Home 1.jpg";
import Home2 from "../../../assets/Home/Home 2.jpg";
import Home3 from "../../../assets/Home/Home 3.jpg";
import Home4 from "../../../assets/Home/Home 4.jpg";
import Home5 from "../../../assets/Home/Home 5.jpg";
import Home6 from "../../../assets/Home/Home 6.jpg";
import Home7 from "../../../assets/Home/Home 7.jpg";
import Home8 from "../../../assets/Home/Home 8.jpg";
import Home9 from "../../../assets/Home/Home 9.jpg";
import Home10 from "../../../assets/Home/Home 10.jpg";
import Home11 from "../../../assets/Home/Home 11.jpg";
import Home12 from "../../../assets/Home/Home 12.jpg";
import Home13 from "../../../assets/Home/Home 13.jpg";
import Home14 from "../../../assets/Home/Home 14.jpg";
import Home15 from "../../../assets/Home/Home 15.jpg";
import Home16 from "../../../assets/Home/Home 16.jpg";

export const HomeImages = [
  Home1,
  Home2,
  Home3,
  Home4,
  Home5,
  Home6,
  Home7,
  Home8,
  Home9,
  Home10,
  Home11,
  Home12,
  Home13,
  Home14,
  Home15,
  Home16,
];
