import React from "react";
import Img from "./logo.png";
import style from "./style.module.scss";

export default function Logo() {
  return (
    <div className={style.logoContainer}>
      <img alt="Karya Delano" className={style.logo} src={Img} />
    </div>
  );
}
