import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import style from "./style.module.scss";

export default function Services() {
  return (
    <div>
      <div className={style.content}>
        <Container fluid>
          <Row>
            <Col xl={{ span: 8, offset: 1 }}>
              <p className={style.weAre}>
                We are ready to compete in quality and commercial aspects
                effectively and efficiently.
              </p>
            </Col>
            <Col xl={{ span: 10, offset: 1 }}>
              <Row className={style.details}>
                <Col xl={3}>
                  <p className={style.title}>Design Planning & Development</p>
                  <p className={style.description}>
                    Planning of architectural design, interior, and exhibition.
                    The scope of work consists of design concepts, pre-plans,
                    development, construction document, and periodic monitoring.
                  </p>
                  <p className={style.description}>
                    Perancanaan desain arsitektur, interior, dan exhibition.
                    Lingkup pekerjaan terdiri dari konsep desain, pra rencana,
                    pengembangan, dokumen konstruksi, dan pengawasan berkala
                  </p>
                </Col>
                <Col xl={3}>
                  <p className={style.title}>Furniture & Mebeleur</p>
                  <p className={style.description}>
                    We provide furniture and mebeleur manufacturing services,
                    custom, knockdown, as well as built in.
                  </p>
                  <p className={style.description}>
                    Kami menyediakan jasa pembuatan furniture dan mebeleur,
                    custom, knockdown, maupun built in.
                  </p>
                </Col>
                <Col xl={3}>
                  <p className={style.title}>Product Promotion</p>
                  <p className={style.description}>
                    Services for the manufacture and procurement of promotional
                    products.
                  </p>
                  <p className={style.description}>
                    Jasa pembuatan dan pengadaan produk promosi.
                  </p>
                </Col>
                <Col xl={3}>
                  <p className={style.title}>Contracting</p>
                  <p className={style.description}>
                    Interior, residential and commercial construction services,
                    and exhibition booth development.
                  </p>
                  <p className={style.description}>
                    Jasa pelaksanaan konstruksi interior, hunian maupun
                    komersil, dan pembuatan booth pameran.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
