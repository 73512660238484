import React from "react";
import { Route } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import "../styles/style.css";

// Components
import Logo from "../components/Logo";
import BurgerMenu from "../components/BurgerMenu";

// Routes
import Home from "../pages/Home";
import Services from "../pages/Services";
import Works from "../pages/Works";
import Contacts from "../pages/Contacts";

const routes = [
  { path: "/", name: "Home", Component: Home },
  { path: "/services", name: "Services", Component: Services },
  { path: "/works", name: "Works", Component: Works },
  { path: "/contact", name: "Contacts", Component: Contacts },
];

export default function Routes() {
  return (
    <>
      <Logo />
      <BurgerMenu />
      {routes.map(({ path, Component }) => (
        <Route key={path} exact path={path}>
          {({ match }) => (
            <CSSTransition
              in={match != null}
              timeout={300}
              classNames="page"
              unmountOnExit
            >
              <div className="page">
                <Component />
              </div>
            </CSSTransition>
          )}
        </Route>
      ))}
    </>
  );
}
