import Architecture1 from "../../../assets/Architecture/1. Architecture 1.jpg";
import Architecture2 from "../../../assets/Architecture/2. Architecture 2.jpg";
import Architecture3 from "../../../assets/Architecture/ARCHITECTURE-1.jpg";
import Architecture4 from "../../../assets/Architecture/ARCHITECTURE-2.jpg";
import Architecture5 from "../../../assets/Architecture/ARCHITECTURE-3.jpg";
import Architecture6 from "../../../assets/Architecture/ARCHITECTURE-4.jpg";
import Architecture7 from "../../../assets/Architecture/ARCHITECTURE-5.jpg";
import Architecture8 from "../../../assets/Architecture/ARCHITECTURE-6.jpg";
import Architecture9 from "../../../assets/Architecture/ARCHITECTURE-7.jpg";
import Architecture10 from "../../../assets/Architecture/ARCHITECTURE-8.jpg";
import Architecture11 from "../../../assets/Architecture/ARCHITECTURE-9.jpg";
import Architecture12 from "../../../assets/Architecture/ARCHITECTURE-10.jpg";
import Architecture13 from "../../../assets/Architecture/ARCHITECTURE-11.jpg";
import Architecture14 from "../../../assets/Architecture/ARCHITECTURE-12.jpg";
import Architecture15 from "../../../assets/Architecture/ARCHITECTURE-13.jpg";
import Architecture16 from "../../../assets/Architecture/ARCHITECTURE-14.jpg";
import Architecture17 from "../../../assets/Architecture/ARCHITECTURE-15.jpg";
import Architecture18 from "../../../assets/Architecture/ARCHITECTURE-16.jpg";

export const ArchitectureImages = [
  Architecture1,
  Architecture2,
  Architecture3,
  Architecture4,
  Architecture5,
  Architecture6,
  Architecture7,
  Architecture8,
  Architecture9,
  Architecture10,
  Architecture11,
  Architecture12,
  Architecture13,
  Architecture14,
  Architecture15,
  Architecture16,
  Architecture17,
  Architecture18,
];
