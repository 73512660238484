import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import { ArchitectureImages } from "./components/ArchitectureImages";
import { ExhibitionImages } from "./components/ExhibitionImages";
import { InteriorImages } from "./components/InteriorImages";
import style from "./style.module.scss";
import ElementResizeDetectorMaker from "element-resize-detector";

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, zIndex: 1, left: "-3rem" }}
      onClick={onClick}
    />
  );
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, right: "-1.8rem" }}
      onClick={onClick}
    />
  );
}

export default function Works() {
  const erd = ElementResizeDetectorMaker({
    strategy: "scroll",
  });

  const refArc = useRef();
  const refInt = useRef();
  const refExh = useRef();

  const [architectureHeight, setArchitectureHeight] = useState(0);
  const [interiorHeight, setInteriorHeight] = useState(0);
  const [exhibitionHeight, setExhibitionHeight] = useState(0);
  useEffect(() => {
    erd.listenTo(refArc.current, (el) => {
      setArchitectureHeight(el.offsetHeight);
    });

    erd.listenTo(refInt.current, (el) => {
      setInteriorHeight(el.offsetHeight);
    });

    erd.listenTo(refExh.current, (el) => {
      setExhibitionHeight(el.offsetHeight);
    });
  }, [erd]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    centerMode: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow className="arrowSize" />,
    prevArrow: <SamplePrevArrow className="arrowSize" />,
    responsive: [
      {
        breakpoint: 1199.98,
        settings: {
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 767.98,
        settings: {
          arrows: false,
          dots: false,
        },
      },
    ],
  };

  const [imgZoom, setImgZoom] = useState({ zoom: false, val: "" });

  const handleZoomImage = (val) => {
    setImgZoom({ zoom: true, val: val });
  };

  const handleCloseZoom = () => {
    setImgZoom({ ...imgZoom, zoom: false });
  };
  return (
    <div>
      {imgZoom.zoom && (
        <div onClick={() => handleCloseZoom()} className={style.imgOverlay}>
          <img src={imgZoom.val} alt="" />
        </div>
      )}
      <div className={style.content}>
        <Container fluid>
          <Row>
            <Col md={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 1 }}>
              <div ref={(e) => (refArc.current = e)}>
                <Slider centerMode={true} {...settings}>
                  {ArchitectureImages.map((val, index) => {
                    return (
                      <div
                        key={index}
                        className={style.itemContainer}
                        onClick={() => handleZoomImage(val)}
                      >
                        <img className={style.itemImg} src={val} alt="" />
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </Col>
            <Col xl={1} className="pointer-events-none">
              <div
                className={style.title}
                style={{ height: `${architectureHeight}px` }}
              >
                ARCHITECTURE
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 1 }}>
              <div ref={(e) => (refInt.current = e)}>
                <Slider centerMode={true} {...settings}>
                  {InteriorImages.map((val, index) => {
                    return (
                      <div
                        key={index}
                        className={style.itemContainer}
                        onClick={() => handleZoomImage(val)}
                      >
                        <img className={style.itemImg} src={val} alt="" />
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </Col>
            <Col xl={1} className="pointer-events-none">
              <div
                className={style.title}
                style={{ height: `${interiorHeight}px` }}
              >
                INTERIOR
              </div>
            </Col>
          </Row>
          <Row className="my-5">
            <Col md={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 1 }}>
              <div ref={(e) => (refExh.current = e)}>
                <Slider centerMode={true} {...settings}>
                  {ExhibitionImages.map((val, index) => {
                    return (
                      <div
                        key={index}
                        className={style.itemContainer}
                        onClick={() => handleZoomImage(val)}
                      >
                        <img className={style.itemImg} src={val} alt="" />
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </Col>
            <Col xl={1} className="pointer-events-none">
              <div
                className={style.title}
                style={{ height: `${exhibitionHeight}px` }}
              >
                EXHIBITION
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
