import React, { useState } from "react";
import { Button, Row, Container, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";

var styles = {
  bmBurgerButton: {
    position: "fixed",
    width: "36px",
    height: "30px",
    right: "36px",
    top: "36px",
  },
  bmBurgerBars: {
    background: "#fff",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
    left: "8px",
  },
  bmCross: {
    background: "#bdc3c7",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
  },
  bmMenu: {
    background: "#3f3f41",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
    boxShadow: "0 10px 20px rgba(0,0,0,.2), 0 6px 6px rgba(0,0,0,.2)",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0.8em",
  },
  bmItem: {
    display: "inline-block",
  },
  bmOverlay: {
    background: "#0000005e",
  },
};

export default function BurgerMenu() {
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <Menu
        isOpen={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        right
        styles={styles}
      >
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Button
                variant="link"
                className="menu-item text-center"
                onClick={() => {
                  setIsOpen(false);
                  history.push("/");
                }}
                block
              >
                Home
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Button
                variant="link"
                className="menu-item"
                onClick={() => {
                  setIsOpen(false);
                  history.push("/services");
                }}
                block
              >
                Services
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Button
                variant="link"
                className="menu-item"
                onClick={() => {
                  setIsOpen(false);
                  history.push("/works");
                }}
                block
              >
                Works
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Button
                variant="link"
                className="menu-item"
                onClick={() => {
                  setIsOpen(false);
                  history.push("/contact");
                }}
                block
              >
                Contact
              </Button>
            </Col>
          </Row>
        </Container>
      </Menu>
    </div>
  );
}
