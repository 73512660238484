import Exhibition1 from "../../../assets/Exhibition/EXHIBITION-1.jpg";
import Exhibition2 from "../../../assets/Exhibition/EXHIBITION-2.jpg";
import Exhibition3 from "../../../assets/Exhibition/EXHIBITION-3.jpg";
import Exhibition4 from "../../../assets/Exhibition/EXHIBITION-4.jpg";
import Exhibition5 from "../../../assets/Exhibition/EXHIBITION-5.jpg";
import Exhibition6 from "../../../assets/Exhibition/EXHIBITION-6.jpg";
import Exhibition7 from "../../../assets/Exhibition/EXHIBITION-7.jpg";
import Exhibition8 from "../../../assets/Exhibition/EXHIBITION-8.jpg";

export const ExhibitionImages = [
  Exhibition1,
  Exhibition2,
  Exhibition3,
  Exhibition4,
  Exhibition5,
  Exhibition6,
  Exhibition7,
  Exhibition8,
];
