import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ContactUsImage from "../../assets/Contact/contact_image.jpg";
import style from "./style.module.scss";

export default function Contacts() {
  return (
    <div>
      <div className={style.content}>
        <Container>
          <Row>
            <Col xs={12} lg={6} className="d-none d-lg-block">
              <div className={style.imageContainer}>
                <img
                  className={style.image}
                  src={ContactUsImage}
                  width="100%"
                  height="auto"
                  alt="Contact Us"
                />
              </div>
            </Col>
            <Col xs={12} lg={6}>
              <Row className={style.fullHeight}>
                <Col xs={12}>
                  <div className={style.kantor}>
                    <div className={style.title}>Kantor</div>
                    <div className={style.description}>
                      Jln.Puring AG/6 Rengas Ciputat
                    </div>
                  </div>
                </Col>
                <Col xs={12}>
                  <div className={style.workshop}>
                    <div className={style.title}>Workshop</div>
                    <div className={style.description}>
                      Komp.Pertamina Rengas Ciputat
                    </div>
                  </div>
                </Col>
                <Col xs={12}>
                  <div className={style.contact}>
                    <div className={style.title}>Contact </div>
                    <div className={style.description}>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://wa.me/+6285883179290"
                      >
                        Hermidar Bahri : 08588-317-9290
                      </a>
                      <br />
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://wa.me/+628128705104"
                      >
                        Ari Muladi : 0812-87-05104
                      </a>
                    </div>
                  </div>
                </Col>
                <Col xs={12}>
                  <div className={style.email}>
                    <div className={style.title}>Email</div>
                    <div className={style.description}>
                      karyadelano@gmail.com <br />
                      info@karyadelano.com
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col xs={12} lg={6} className="d-lg-none">
              <div className={style.imageContainer}>
                <img
                  className={style.image}
                  src={ContactUsImage}
                  width="100%"
                  height="auto"
                  alt="Contact Us"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
